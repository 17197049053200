import * as React from "react"
import "@fontsource/ibm-plex-mono/600.css"
import "@fontsource/ibm-plex-mono"
import "@fontsource/ibm-plex-sans"
import Landing from "../components/Landing"
import Nav from "../components/Nav"
import Seo from "../components/Seo"
import LanguageContext from "../context/LanguageContext"
import "../styles/common.scss"

class IndexPage extends React.Component {
  render() {
    return <>
      <LanguageContext>
        <Seo />
        <main>
          <Nav />
          <Landing />
        </main>
      </LanguageContext>
    </>
  }
}

export default IndexPage
