import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = (props) => {
  const { site } = useStaticQuery(query)

  const {
    title,
    titleTemplate,
    description,
    url
  } = site.siteMetadata

  const seo = {
    title: title,
    description: description,
    url
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
    </Helmet>
  )
}

export default SEO

const query = graphql`
query SEO {
    site {
        siteMetadata {
          title
          titleTemplate
          description
          url
      }
    }
}
  `