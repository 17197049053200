import React, { useContext } from "react"
import { LanguageContext } from "../context/LanguageContext"
import * as Text from "../constants/text";
import "../styles/landing.scss"

const Landing = (props) => {
  const { languageToUse } = useContext(LanguageContext);
  return (
    <section className="landingSection">
      <article>
        <h2>{Text.LANDING_HEADER[languageToUse].subHeader}</h2>
        <h3>{Text.LANDING_HEADER[languageToUse].wip}</h3>
      </article>
    </section>
  )
}

export default Landing
