export const LANDING_HEADER = {
  spanish: {
    header: '¡Hola! Soy René Vidríales.',
    subHeader: 'Desarrollador',
    wip: 'Sitio en construcción...'
  },
  english: {
    header: "Hello! I'm René Vidríales",
    subHeader: 'Developer',
    wip: 'Site in progress...'
  }
};

export const NAV_HEADER = {
  name: 'RENÉ VIDRÍALES TRUJILLO'
};